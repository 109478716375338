import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import Vue3TouchEvents from "vue3-touch-events";
import * as Sentry from "@sentry/vue";



const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        let vue = createApp({
            render: () => h(App, props), components: {
                // Add your components here
            }
        })

        Sentry.init({
            vue,
            dsn: "https://bd2a3da3bf2e851349eee7614a88db94@o4506614526509056.ingest.sentry.io/4506614640082944",
            environment: import.meta.env.APP_ENV,
            integrations: [
                new Sentry.BrowserTracing({
                  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: [/^https:\/\/roblossing\.be/,/^https:\/\/www.roblossing\.be/],
                    tracingOrigins: ["localhost", "https://roblossing.be", "https://www.roblossing.be"],
                }),
                new Sentry.BrowserProfilingIntegration(),
                new Sentry.Replay(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        vue.use(plugin)
        vue.use(ZiggyVue, Ziggy)
        vue.use(Vue3TouchEvents)

        return vue.mount(el);
    },
    progress: {
        color: '#39A8E0',
    },
});
